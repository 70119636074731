import React from "react"

import * as L from "layouts"

const NotFoundPage = () => (
  <L.Layout404>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </L.Layout404>
)

export default NotFoundPage
